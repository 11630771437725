import { graphql } from 'gatsby'
import Image, { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import IllustrationCardDeck from '../../../components/illustration-cards'

import { IllustrationEssay } from '../../../components/illustration-cards/types'
import { GraphQLQuery } from '../../../types'

const mockData: IllustrationEssay = {
  title: 'A love letter to my favourite plants, as told through illustrations',
  author: 'Isabella Tan',
  description:
    "In a world of flux, one might feel a sense of rootless existence. For a busy, city-living human like myself, I know to expect the unexpected — that the unchanged will always change. In a world in flux, gardening provides a sense of stability and calm. At the same time, plants are also experiencing constant change — as subtle as it may seem — each day. There is growth in the fruits and flowers, but there is also death in the fallen leaves. Each plant comes with its own temperament and personality.",
  images: [
    {
      title: 'African Spear (***Dracaena angolensis***)',
      content:
        'True to its name, the leaves of the African Spear are proudly tall, spiky, and sturdy. In the wind, it never wavers. It is one of the few plant species that has crassulacean acid metabolism, a carbon fixation system that makes the plant resistant to drought.',
      image: '' as any
    },
    {
      title: 'Pencil Cactus (***Euphorbia tirucalli***)',
      content:
        'Velvety and succulent, the pencil cactus is full of surprises. It excretes a milky sap that could cause blindness if it comes in contact with the eyes, and that could be fatal if ingested. It is a euphorbia — and not actually a cactus — and it photosynthesises through its stems. It can turn into vivid shades of orange, pink, or yellow during cool months.',
      image: '' as any
    },
    {
      title: 'Satin Pothos (***Scindapsus pictus**)',
      content:
        'Heart shaped with splashes of silver and moss green, the satin pothos adapts to its environment through its size and vines. The patterns on its leaves are never constant.',
      image: '' as any
    },
    {
      title: '***Stephania erecta***',
      content: 'Like a potato, *Stephania erecta* begins with no roots and no leaves. However, with tender loving care, it sprouts multiple tubers, which can intertwine with any shape of wire. The leaves thrive in spring but fall in winter.',
      image: '' as any
    }
  ]
}
 
type Props = {
  allFile: {
    nodes: {
      name: string
      childImageSharp: GatsbyImageFluidProps
    }[]
  }
}

const IllustrationPage = ({ data: {allFile}}: GraphQLQuery<Props>) => {
  for (const image of mockData.images) {
    for (const node of allFile.nodes) {
      if (image.title.includes(node.name)) {
        console.log(node.childImageSharp)
        image.image = node.childImageSharp
      }
    }
  }
  console.log(mockData)
  return (
    <div>
      <Image fluid={allFile.nodes.find(node => node.name === 'cover')?.childImageSharp.fluid!} />
      <p>A love letter to my favourite plants, as told through illustrations</p>
      <p>In a world in flux, gardening provides a sense of stability and calm</p>
      <p>{mockData.author}</p>
      <p>{mockData.description}</p>
      <IllustrationCardDeck essay={mockData} />
    </div>
  )
}

export default IllustrationPage

export const query = graphql`
  query IlloQuery {
    allFile(filter: {relativeDirectory: {eq: "illustration-essay"}}) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }  
`
