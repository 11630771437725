import React, { useRef, useState } from 'react'
import IllustrationCard from './card'
import { IllustrationEssay, MoveFunc } from './types'

interface Props {
  essay: IllustrationEssay
  className?: string
}

const IllustrationCardDeck = ({
  essay: { title, author, description, images },
  className
}: Props) => {
  const [active, setActive] = useState<number>(0)
  const generateButtons = (show?: boolean) => {
      const onClick = (incr: number) => (
        e: React.BaseSyntheticEvent
      ) => {
        e.preventDefault()
        if ((active !== 0 && incr === -1) || (active !== images.length - 1 && incr === 1)) setActive(active => active + incr)
      }
    return (
      <>
       <svg onClick={onClick(-1)} className={ `transition-all w-6 h-6 text-black cursor-pointer ${active === 0 ? 'opacity-0' : ''}` } fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z" clip-rule="evenodd"></path></svg>

      <svg onClick={onClick(1)} className={ `transition-all w-6 h-6 text-black cursor-pointer ${active === images.length - 1 ? 'opacity-0' : ''}` } fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd"></path></svg>
      </>
    )
  }
  return (
    <div className={`max-w-md p-4 bg-gray-50 h-full ${className}`}>
     <IllustrationCard card={images[active]} navButtons={generateButtons} />
      <div className={`opacity-40 relative z-30 mx-auto text-center`} style={{bottom: "-50%"}}>
        <div className={`flex flex-row justify-between`}>
          {generateButtons()}
        </div>
      </div>
    </div>
  )
}

export default IllustrationCardDeck
