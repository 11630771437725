import React, { useState } from 'react'
import { IllustrationEssayImage, MoveFunc } from './types'
import Image, { GatsbyImageFluidProps } from 'gatsby-image'
import ReactCardFlip from 'react-card-flip'
import Vimeo from '@u-wave/react-vimeo'
import './styles.css'
import ReactPlayer from 'react-player'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from '@react-hook/media-query'

interface Props {
  card: IllustrationEssayImage
  navButtons: (shodw?: boolean) => any
}

const IllustrationCard = ({ card, navButtons }: Props) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)

  const isMobile = useMediaQuery('only screen and (max-width: 768px)')
  const {title, content, image} = card

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    setIsFlipped(!isFlipped)
  }
  
  console.log('test')
  console.log(image as string)
  return (
    <>
    <div className="bg-white p-4 rounded-md illustration-card h-96" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      <div onClick={onClick} className="relative z-30 mx-auto text-center">
        Click here to read it 
      </div>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        {/* Card front */}
        <div className="mx-auto" onClick={onClick}>
          {(image as GatsbyImageFluidProps).fluid ? 
          <Image fluid={(image as GatsbyImageFluidProps).fluid} />
          : <img src={image as string} />
          }
          <h1 className="text-gray-800 text-center">{title.includes('Shot') ? '' : title}</h1>
        </div>

        {/* Card back */}
        <div onClick={onClick}>
          <div
            className="text-gray-700 font-light"
            dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </ReactCardFlip>
    </div>
    </>
  )
}

export default IllustrationCard
